// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme((color: (primary: $app-primary,
        accent: $app-accent,
        warn: $app-warn,
      ),
      typography: mat.define-typography-config(),
      density: 0));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// The app direction is used to include
// rtl styles in your app. For more info, please see:
// http://ionicframework.com/docs/theming/rtl-support/
$app-direction: ltr;

/** Ionic CSS Variables **/
:root {

  // NOTE: disable dynamic font scaling - see https://ionicframework.com/docs/updating/8-0#dynamic-font
  --ion-dynamic-font: initial;

  /** lightwarning **/
  --ion-color-lightwarning: #ffe200;
  --ion-color-lightwarning-rgb: 255, 226, 0;
  --ion-color-lightwarning-contrast: #000000;
  --ion-color-lightwarning-contrast-rgb: 0, 0, 0;
  --ion-color-lightwarning-shade: #e0c700;
  --ion-color-lightwarning-tint: #ffe51a;
  //$item-ios-body-text-font-size: 1.4rem;

  ion-item {
    font-size: 0.8rem;
  }

  ion-tab-button {
    font-size: 0.8rem;
  }

  // Ionicons
  // --------------------------------------------------
  // The premium icon font for Ionic. For more info, please see:
  // http://ionicframework.com/docs/ionicons/

  ion-label {
    font-size: 0.8rem;
  }


  signature-pad {
    canvas {
      width: 100%;
      height: 200px;
      background-color: white;
      box-shadow: inset 0 0 10px #387ef5;
      border-radius: 4px;
    }
  }

  --courier-color-light: whitesmoke;
}

.ion-color-lightwarning {
  --ion-color-base: var(--ion-color-lightwarning);
  --ion-color-base-rgb: var(--ion-color-lightwarning-rgb);
  --ion-color-contrast: var(--ion-color-lightwarning-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightwarning-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightwarning-shade);
  --ion-color-tint: var(--ion-color-lightwarning-tint);
}

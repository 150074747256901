// http://ionicframework.com/docs/theming/
@import '@ionic/angular/css/core.css';
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import 'assets/css/font-awesome-animation.css';

/* @import '@ionic/angular/css/palettes/dark.always.css'; */
@import "@ionic/angular/css/palettes/dark.class.css";
/* @import '@ionic/angular/css/palettes/dark.system.css'; */

// NOTE: use webfonts for map markers because SVG Javascript Code package does not work reliably
// on real devices and shows white screen of death or cause other problems
// as discussed here https://github.com/FortAwesome/angular-fontawesome/issues/171
$fa-font-path: '~@fortawesome/fontawesome-pro-webfonts/webfonts';
@import '@fortawesome/fontawesome-pro-webfonts/scss/fontawesome';
@import '@fortawesome/fontawesome-pro-webfonts/scss/fa-solid';
@import '@fortawesome/fontawesome-pro-webfonts/scss/fa-regular';

@import 'src/app/app.scss';

:root {

  --ion-dynamic-font: initial;

  @mixin fa-base() {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @mixin fa5-base() {
    display: inline-block;
    font: normal normal normal 14px/1 'Font Awesome 5 Pro';
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  // fa-car
  .ion-md-fa-car, .ion-ios-fa-car, .ion-ios-fa-car-outline {
    @include fa5-base();
  }

  .ion-md-fa-car:before, .ion-ios-fa-car:before, .ion-ios-fa-car-outline:before {
    content: "\f1b9";
  }

  // fa-truck
  .ion-md-fa-truck, .ion-ios-fa-truck, .ion-ios-fa-truck-outline {
    @include fa-base();
  }

  .ion-md-fa-truck:before, .ion-ios-fa-truck:before, .ion-ios-fa-truck-outline:before {
    content: "\f0d1";
  }

  // fa-inbox
  .ion-md-fa-inbox, .ion-ios-fa-inbox, .ion-ios-fa-inbox-outline {
    @include fa-base();
  }

  .ion-md-fa-inbox:before, .ion-ios-fa-inbox:before, .ion-ios-fa-inbox-outline:before {
    content: "\f01c";
  }

  // fa-building
  .ion-md-fa-building, .ion-ios-fa-building, .ion-ios-fa-building-outline {
    @include fa5-base();
  }

  .ion-md-fa-building:before, .ion-ios-fa-building:before, .ion-ios-fa-building-outline:before {
    content: "\f1ad";
  }

  // fa-reply
  .ion-md-fa-reply, .ion-ios-fa-reply, .ion-ios-fa-reply-outline {
    @include fa-base();
  }

  .ion-md-fa-reply:before, .ion-ios-fa-reply:before, .ion-ios-fa-reply-outline:before {
    content: "\f112";
  }

  // fa-arrow-circle
  .ion-md-fa-arrow-circle, .ion-ios-fa-arrow-circle, .ion-ios-fa-arrow-circle-outline {
    @include fa5-base();
  }

  .ion-md-fa-arrow-circle:before, .ion-ios-fa-arrow-circle:before, .ion-ios-fa-arrow-circle-outline:before {
    content: "\f190";
  }

  // fa-arrow-circle-left
  .ion-md-fa-arrow-circle-left, .ion-ios-fa-arrow-circle-left, .ion-ios-fa-arrow-circle-left-outline {
    @include fa5-base();
  }

  .ion-md-fa-arrow-circle-left:before, .ion-ios-fa-arrow-circle-left:before, .ion-ios-fa-arrow-circle-left-outline:before {
    content: "\f0a8";
  }

  // fa-cubes
  .ion-md-fa-cubes, .ion-ios-fa-cubes, .ion-ios-fa-cubes-outline {
    @include fa-base();
  }

  .ion-md-fa-cubes:before, .ion-ios-fa-cubes:before, .ion-ios-fa-cubes-outline:before {
    content: "\f1b3";
  }

  .mbls-text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 5px;
  }

  .badge-md-dull {
    background-color: lightgray;
    color: grey;
  }

  ion-button {
    text-transform: none !important;
  }

  .actions-footer {
    border-top: 2px solid;
    text-align: center;
  }

  .workflow_badge {
    background-color: orange;
  }

  .workflow_pickup {
    background-color: orange;
    color: black;
  }

  .recurrence_badge {
    background-color: #4D90FD;
    color: white;
  }

  .collect_badge {
    color: blue;
    background-color: transparent;
  }

  .order_notes_badge {
    background-color: #5cb85c;
  }

  .client_notes_badge {
    background-color: #5BC0DE;
  }

  .driver_notes_badge {
    background-color: chocolate;
  }

  .signature_thumb {
    border: 1px solid black;
    background-color: whitesmoke;
  }

  .italic {
    font-style: italic;
  }

  .text-muted {
    opacity: 0.5;
  }

  .text-center {
    text-align: center;
  }

  .item-no-padding {
    padding: 0 !important;
    --inner-padding-end: 0px;
    --padding-start: 0px;
  }

  .item-diagnostic > .item-inner {
    padding: 4px !important;
  }

  .item-no-padding ion-label {
    margin: 0 !important;
  }

  .popover-ios .popover-content {
    width: 300px;
  }

  .text-bold {
    font-weight: bold;
  }

  .no-margin {
    margin: 0 !important;
  }

  .text-xxlarge {
    font-size: xx-large;
  }

  .order-item-fill {
    width: 100%;
    padding: 0;
  }

  .fa-bar-button {
    padding: 0 !important;
  }

  .connected {
    color: var(--ion-color-success);
  }

  .disconnected {
    color: var(--ion-color-danger);
  }

  .small-modal {
    background: rgba(0, 0, 0, 0.5) !important;
    padding: 40% 10% !important;
  }

  /*
  .processing-toast {
    --background: black;
    --color:white;
  }
  */

  .processing-toast {
    .toast-message {
      color: black;
    }

    .toast-container {
      background-color: darkgray;
    }
  }


  // Animation for font-awesome
  /* WRENCHING */
  @keyframes wrench {
    0% {
      transform: rotate(-12deg)
    }
    8% {
      transform: rotate(12deg)
    }
    10% {
      transform: rotate(24deg)
    }
    18% {
      transform: rotate(-24deg)
    }
    20% {
      transform: rotate(-24deg)
    }
    28% {
      transform: rotate(24deg)
    }
    30% {
      transform: rotate(24deg)
    }
    38% {
      transform: rotate(-24deg)
    }
    40% {
      transform: rotate(-24deg)
    }
    48% {
      transform: rotate(24deg)
    }
    50% {
      transform: rotate(24deg)
    }
    58% {
      transform: rotate(-24deg)
    }
    60% {
      transform: rotate(-24deg)
    }
    68% {
      transform: rotate(24deg)
    }
    75%, 100% {
      transform: rotate(0deg)
    }
  }

  .faa-wrench.animated,
  .faa-wrench.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-wrench {
    animation: wrench 2.5s ease infinite;
    // transform-origin-x: 90%;
    // transform-origin-y: 35%;
    // transform-origin-z: initial;
    transform-origin: 90% 35% initial;
  }

  .faa-wrench.animated.faa-fast,
  .faa-wrench.animated-hover.faa-fast:hover,
  .faa-parent.animated-hover:hover > .faa-wrench.faa-fast {
    animation: wrench 1.2s ease infinite;
  }

  .faa-wrench.animated.faa-slow,
  .faa-wrench.animated-hover.faa-slow:hover,
  .faa-parent.animated-hover:hover > .faa-wrench.faa-slow {
    animation: wrench 3.7s ease infinite;
  }

  /* BELL */
  @keyframes ring {
    0% {
      transform: rotate(-15deg)
    }
    2% {
      transform: rotate(15deg)
    }
    4% {
      transform: rotate(-18deg)
    }
    6% {
      transform: rotate(18deg)
    }
    8% {
      transform: rotate(-22deg)
    }
    10% {
      transform: rotate(22deg)
    }
    12% {
      transform: rotate(-18deg)
    }
    14% {
      transform: rotate(18deg)
    }
    16% {
      transform: rotate(-12deg)
    }
    18% {
      transform: rotate(12deg)
    }
    20%, 100% {
      transform: rotate(0deg)
    }
  }

  .faa-ring.animated,
  .faa-ring.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-ring {
    animation: ring 2s ease infinite;
    // transform-origin-x: 50%;
    // transform-origin-y: 0px;
    // transform-origin-z: initial;
    transform-origin: 50% 0 initial;
  }

  .faa-ring.animated.faa-fast,
  .faa-ring.animated-hover.faa-fast:hover,
  .faa-parent.animated-hover:hover > .faa-ring.faa-fast {
    animation: ring 1s ease infinite;
  }

  .faa-ring.animated.faa-slow,
  .faa-ring.animated-hover.faa-slow:hover,
  .faa-parent.animated-hover:hover > .faa-ring.faa-slow {
    animation: ring 3s ease infinite;
  }

  /* VERTICAL */
  @keyframes vertical {
    0% {
      transform: translate(0, -3px)
    }
    4% {
      transform: translate(0, 3px)
    }
    8% {
      transform: translate(0, -3px)
    }
    12% {
      transform: translate(0, 3px)
    }
    16% {
      transform: translate(0, -3px)
    }
    20% {
      transform: translate(0, 3px)
    }
    22%, 100% {
      transform: translate(0, 0)
    }
  }

  .faa-vertical.animated,
  .faa-vertical.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-vertical {
    animation: vertical 2s ease infinite;
  }

  .faa-vertical.animated.faa-fast,
  .faa-vertical.animated-hover.faa-fast:hover,
  .faa-parent.animated-hover:hover > .faa-vertical.faa-fast {
    animation: vertical 1s ease infinite;
  }

  .faa-vertical.animated.faa-slow,
  .faa-vertical.animated-hover.faa-slow:hover,
  .faa-parent.animated-hover:hover > .faa-vertical.faa-slow {
    animation: vertical 4s ease infinite;
  }

  /* HORIZONTAL */
  @keyframes horizontal {
    0% {
      transform: translate(0, 0)
    }
    6% {
      transform: translate(5px, 0)
    }
    12% {
      transform: translate(0, 0)
    }
    18% {
      transform: translate(5px, 0)
    }
    24% {
      transform: translate(0, 0)
    }
    30% {
      transform: translate(5px, 0)
    }
    36%, 100% {
      transform: translate(0, 0)
    }
  }

  .faa-horizontal.animated,
  .faa-horizontal.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-horizontal {
    animation: horizontal 2s ease infinite;
  }

  .faa-horizontal.animated.faa-fast,
  .faa-horizontal.animated-hover.faa-fast:hover,
  .faa-parent.animated-hover:hover > .faa-horizontal.faa-fast {
    animation: horizontal 1s ease infinite;
  }

  .faa-horizontal.animated.faa-slow,
  .faa-horizontal.animated-hover.faa-slow:hover,
  .faa-parent.animated-hover:hover > .faa-horizontal.faa-slow {
    animation: horizontal 3s ease infinite;
  }

  /* FLASHING */
  @keyframes flash {
    0%, 100%, 50% {
      opacity: 1
    }
    25%, 75% {
      opacity: 0
    }
  }

  .faa-flash.animated,
  .faa-flash.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-flash {
    animation: flash 2s ease infinite;
  }

  .faa-flash.animated.faa-fast,
  .faa-flash.animated-hover.faa-fast:hover,
  .faa-parent.animated-hover:hover > .faa-flash.faa-fast {
    animation: flash 1s ease infinite;
  }

  .faa-flash.animated.faa-slow,
  .faa-flash.animated-hover.faa-slow:hover,
  .faa-parent.animated-hover:hover > .faa-flash.faa-slow {
    animation: flash 3s ease infinite;
  }

  /* BOUNCE */
  @keyframes bounce {
    0%, 10%, 20%, 50%, 80%, 100% {
      transform: translateY(0)
    }
    40% {
      transform: translateY(-15px)
    }
    60% {
      transform: translateY(-15px)
    }
  }

  .faa-bounce.animated,
  .faa-bounce.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-bounce {
    animation: bounce 2s ease infinite;
  }

  .faa-bounce.animated.faa-fast,
  .faa-bounce.animated-hover.faa-fast:hover,
  .faa-parent.animated-hover:hover > .faa-bounce.faa-fast {
    animation: bounce 1s ease infinite;
  }

  .faa-bounce.animated.faa-slow,
  .faa-bounce.animated-hover.faa-slow:hover,
  .faa-parent.animated-hover:hover > .faa-bounce.faa-slow {
    animation: bounce 3s ease infinite;
  }

  /* SPIN */
  @keyframes spin {
    0% {
      transform: rotate(0deg)
    }
    100% {
      transform: rotate(359deg)
    }
  }

  .faa-spin.animated,
  .faa-spin.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-spin {
    animation: spin 1.5s linear infinite;
  }

  .faa-spin.animated.faa-fast,
  .faa-spin.animated-hover.faa-fast:hover,
  .faa-parent.animated-hover:hover > .faa-spin.faa-fast {
    animation: spin 0.7s linear infinite;
  }

  .faa-spin.animated.faa-slow,
  .faa-spin.animated-hover.faa-slow:hover,
  .faa-parent.animated-hover:hover > .faa-spin.faa-slow {
    animation: spin 2.2s linear infinite;
  }

  /* FLOAT */
  @keyframes float {
    0% {
      transform: translateY(0)
    }
    50% {
      transform: translateY(-6px)
    }
    100% {
      transform: translateY(0)
    }
  }

  .faa-float.animated,
  .faa-float.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-float {
    animation: float 2s linear infinite;
  }

  .faa-float.animated.faa-fast,
  .faa-float.animated-hover.faa-fast:hover,
  .faa-parent.animated-hover:hover > .faa-float.faa-fast {
    animation: float 1s linear infinite;
  }

  .faa-float.animated.faa-slow,
  .faa-float.animated-hover.faa-slow:hover,
  .faa-parent.animated-hover:hover > .faa-float.faa-slow {
    animation: float 3s linear infinite;
  }

  /* PULSE */
  @keyframes pulse {
    0% {
      transform: scale(1.1)
    }
    50% {
      transform: scale(0.8)
    }
    100% {
      transform: scale(1.1)
    }
  }

  .faa-pulse.animated,
  .faa-pulse.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-pulse {
    animation: pulse 2s linear infinite;
  }

  .faa-pulse.animated.faa-fast,
  .faa-pulse.animated-hover.faa-fast:hover,
  .faa-parent.animated-hover:hover > .faa-pulse.faa-fast {
    animation: pulse 1s linear infinite;
  }

  .faa-pulse.animated.faa-slow,
  .faa-pulse.animated-hover.faa-slow:hover,
  .faa-parent.animated-hover:hover > .faa-pulse.faa-slow {
    animation: pulse 3s linear infinite;
  }

  /* SHAKE */
  .faa-shake.animated,
  .faa-shake.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-shake {
    animation: wrench 2.5s ease infinite;
  }

  .faa-shake.animated.faa-fast,
  .faa-shake.animated-hover.faa-fast:hover,
  .faa-parent.animated-hover:hover > .faa-shake.faa-fast {
    animation: wrench 1.2s ease infinite;
  }

  .faa-shake.animated.faa-slow,
  .faa-shake.animated-hover.faa-slow:hover,
  .faa-parent.animated-hover:hover > .faa-shake.faa-slow {
    animation: wrench 3.7s ease infinite;
  }

  /* TADA */
  @keyframes tada {
    0% {
      transform: scale(1)
    }
    10%, 20% {
      transform: scale(.9) rotate(-8deg);
    }
    30%, 50%, 70% {
      transform: scale(1.3) rotate(8deg)
    }
    40%, 60% {
      transform: scale(1.3) rotate(-8deg)
    }
    80%, 100% {
      transform: scale(1) rotate(0)
    }
  }

  .faa-tada.animated,
  .faa-tada.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-tada {
    animation: tada 2s linear infinite;
  }

  .faa-tada.animated.faa-fast,
  .faa-tada.animated-hover.faa-fast:hover,
  .faa-parent.animated-hover:hover > .faa-tada.faa-fast {
    animation: tada 1s linear infinite;
  }

  .faa-tada.animated.faa-slow,
  .faa-tada.animated-hover.faa-slow:hover,
  .faa-parent.animated-hover:hover > .faa-tada.faa-slow {
    animation: tada 3s linear infinite;
  }

  /* PASSING */
  @keyframes passing {
    0% {
      transform: translateX(-50%);
      opacity: 0
    }
    50% {
      transform: translateX(0%);
      opacity: 1
    }
    100% {
      transform: translateX(50%);
      opacity: 0
    }
  }

  .faa-passing.animated,
  .faa-passing.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-passing {
    animation: passing 2s linear infinite;
  }

  .faa-passing.animated.faa-fast,
  .faa-passing.animated-hover.faa-fast:hover,
  .faa-parent.animated-hover:hover > .faa-passing.faa-fast {
    animation: passing 1s linear infinite;
  }

  .faa-passing.animated.faa-slow,
  .faa-passing.animated-hover.faa-slow:hover,
  .faa-parent.animated-hover:hover > .faa-passing.faa-slow {
    animation: passing 3s linear infinite;
  }

  /* PASSING REVERSE */

  @keyframes passing-reverse {
    0% {
      transform: translateX(50%);
      opacity: 0
    }
    50% {
      transform: translateX(0%);
      opacity: 1
    }
    100% {
      transform: translateX(-50%);
      opacity: 0
    }
  }

  .faa-passing-reverse.animated,
  .faa-passing-reverse.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-passing-reverse {
    animation: passing-reverse 2s linear infinite;
  }

  .faa-passing-reverse.animated.faa-fast,
  .faa-passing-reverse.animated-hover.faa-fast:hover,
  .faa-parent.animated-hover:hover > .faa-passing-reverse.faa-fast {
    animation: passing-reverse 1s linear infinite;
  }

  .faa-passing-reverse.animated.faa-slow,
  .faa-passing-reverse.animated-hover.faa-slow:hover,
  .faa-parent.animated-hover:hover > .faa-passing-reverse.faa-slow {
    animation: passing-reverse 3s linear infinite;
  }

  /* BURST */
  @keyframes burst {
    0% {
      opacity: .6
    }
    50% {
      transform: scale(1.8);
      opacity: 0
    }
    100% {
      opacity: 0
    }
  }

  .faa-burst.animated,
  .faa-burst.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-burst {
    animation: burst 2s infinite linear
  }

  .faa-burst.animated.faa-fast,
  .faa-burst.animated-hover.faa-fast:hover,
  .faa-parent.animated-hover:hover > .faa-burst.faa-fast {
    animation: burst 1s infinite linear
  }

  .faa-burst.animated.faa-slow,
  .faa-burst.animated-hover.faa-slow:hover,
  .faa-parent.animated-hover:hover > .faa-burst.faa-slow {
    animation: burst 3s infinite linear
  }

  /* FALLING */
  @keyframes falling {
    0% {
      transform: translateY(-50%);
      opacity: 0
    }
    50% {
      transform: translateY(0%);
      opacity: 1
    }
    100% {
      transform: translateY(50%);
      opacity: 0
    }
  }

  .faa-falling.animated,
  .faa-falling.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-falling {
    animation: falling 2s linear infinite;
  }

  .faa-falling.animated.faa-fast,
  .faa-falling.animated-hover.faa-fast:hover,
  .faa-parent.animated-hover:hover > .faa-falling.faa-fast {
    animation: falling 1s linear infinite;
  }

  .faa-falling.animated.faa-slow,
  .faa-falling.animated-hover.faa-slow:hover,
  .faa-parent.animated-hover:hover > .faa-falling.faa-slow {
    animation: falling 3s linear infinite;
  }

  /* RAISING */
  @keyframes raising {
    0% {
      transform: translateY(50%);
      opacity: 0
    }
    50% {
      transform: translateY(0%);
      opacity: 1
    }
    100% {
      transform: translateY(-50%);
      opacity: 0
    }
  }

  .faa-raising.animated,
  .faa-raising.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-raising {
    animation: raising 2s linear infinite;
  }

  .faa-raising.animated.faa-fast,
  .faa-raising.animated-hover.faa-fast:hover,
  .faa-parent.animated-hover:hover > .faa-raising.faa-fast {
    animation: raising 1s linear infinite;
  }

  .faa-raising.animated.faa-slow,
  .faa-raising.animated-hover.faa-slow:hover,
  .faa-parent.animated-hover:hover > .faa-raising.faa-slow {
    animation: raising 3s linear infinite;
  }

  ion-app, ion-nav, ion-navbar, ion-tab, ion-tabs, .mobilus-root, .ion-page {
    &.introjs-fixParent {
      contain: layout size style !important;
    }
  }

  // ~ button:not(.introjs-showElement)
  ion-buttons {
    &.introjs-fixParent {
      ion-button {
        z-index: 999990;
      }
    }
  }

  .introjs-tooltiptext {
    font-size: 1.7em;
  }

  .introjs-button {
    font-size: 1.2em !important;
  }

  .introjs-skipbutton {
    margin-right: 25px !important;
  }

  /*

  .introjs-fixParent {
    contain: layout size style !important;
  }
  */

  @media screen and (max-width: 321px) {
    .top-bar-action-button {
      margin: 0px !important;
    }
  }

  .transition-button fa-icon {
    padding-right: 0.3em;
    text-transform: none !important;
    font-size: large;
    font-weight: bold;
  }

  .transition-button {
    font-size: large;
    font-weight: bold;
  }

  .transitions-buttons-text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.75em;
  }

  ion-button > fa-icon {
    padding-right: 0.2em;
    font-size: medium;
  }

  .one-transition-footer {
    min-height: 10%;
  }

  .one-type-multiple-transition-footer {
    min-height: 17%;
  }

  .two-type-multiple-transition-footer {
    min-height: 25%;
  }

  .update-cloud {
    color: var(--ion-color-success);
  }

  .route-optimization-popover {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.5);
  }
  .route-optimization-popover::part(content) {
      --min-width: fit-content;
  }


    /** DARK MODE COLORS AND STYLES **/
  &.ion-palette-dark {
    
    body {
    
        // Les valeurs doivent être ici, sous le même item dans un fichier .scss d'un composant, ça ne fonctionne pas
        .even_row {
          background-color: #111111;
        }
    
        .odd_row {
          background-color: #1d1d1d;
        }
    
        .alert-wrapper {
          border: 1px solid whitesmoke;
        }
    
      }
  }

}

body {
  // --ion-background-color: #fff;
  // --ion-background-color-rgb: 255, 255, 255;

  .even_row {
    background-color: #e6e6e6;
  }
  .odd_row {
    background-color: #fafafa;
  }

}
/*
 * Dark Colors
 * ------------------
 */

/* Optional CSS, this is added for the flashing that happens when toggling between themes */
ion-item {
  --transition: none;
}

.modal-l::part(content) {
  height: 70% !important;
  width: 80% !important;
  position: absolute;
  display: block;
}

// NOTE: used for order-list.page.html. Does not work if specified in the component scss
cdk-virtual-scroll-viewport {
  height: 100%;
  width: 100%;

  &.can-select-enabled {
    height: calc(100% - 32px);
  }

  .cdk-virtual-scroll-content-wrapper {
    width: 100% !important;

    min-height: 100%;
    display: flex;
    flex-direction: column;
    .visible-orders {
      flex-grow: 1;
    }
    .hidden-orders {
      ion-list {
        padding: 0;
      }
    }

  }
}